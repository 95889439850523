import * as React from "react";
import Layout from "../../components/Layout";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout 
        pageTitle="Membership Form. JVC OSA Canada" 
        pageDescription="JVC OSA Canda Membership Form."
        pageSlug="/membership-form/"
      >
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Memebership Form</h1>
              <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLScD1ARosty3bMVdQa8M-31pbY_CzMOdrEDAZwIRogppgnanrg/viewform?embedded=true" 
                width="100%" 
                height="4200" 
                frameborder="0" 
                marginheight="0" 
                marginwidth="0"
                title="Membership Form - JVC OSA Canada"
              >
                Loading…
              </iframe>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
